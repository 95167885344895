import httpClient  from '../api'; 
const API_CONTROLLER = 'job-estimate/'; 


export default { 
    delete(id){ 
        let url = API_CONTROLLER + 'delete';
        return httpClient.delete(url, {
            params: {
                id: id
            }
        });
    },

    add(data) { 
        let url = API_CONTROLLER + 'add';
        return httpClient.post(url, data);
    },

    edit(data) { 
        let url = API_CONTROLLER + 'edit';
        return httpClient.post(url, data);
    },

    getCode(staffId) { 
        let url = API_CONTROLLER + 'get-code?staffId=' + staffId;
        return httpClient.get(url);
    },

    getById(id) { 
        let url = API_CONTROLLER + 'get-by-id';
        return httpClient.get(url, {
            params: {
                id: id
            }
        });
    },

    getForApprove(id) { 
        let url = API_CONTROLLER + 'get-for-approve';
        return httpClient.get(url, {
            params: {
                id: id
            }
        });
    },

    getForApproves(search) { 
        let url = API_CONTROLLER + 'get-for-approves';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                status: search.status,
                staffStatus: search.staffStatus,
                code: search.code
            }
        });
    },

    filter(search) { 
        let url = API_CONTROLLER + 'filter';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                status: search.status,
                code: search.code
            }
        });
    },

    submitApprove(data) { 
        let url = API_CONTROLLER + 'submit-approved';
        return httpClient.post(url, data);
    },

    requireEdit(data) { 
        let url = API_CONTROLLER + 'require-edit';
        return httpClient.post(url, data);
    },

    getForAdvancePayment(search) { 
        let url = API_CONTROLLER + 'get-for-advance-payment';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                keyword: search.keyword
            }
        });
    },

    getForInvoice(search) { 
        let url = API_CONTROLLER + 'get-for-invoice';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                keyword: search.keyword
            }
        });
    },

    getDepositRepair(search) { 
        let url = API_CONTROLLER + 'get-deposit-repair';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                status: search.status,
                repairStatus: search.repairStatus,
                customerId: search.customerId,
                keyword: search.keyword,
                serial: search.serial
            }
        });
    },

    updateDepositRepair(data) { 
        let url = API_CONTROLLER + 'update-deposit-repair';
        return httpClient.post(url, data);
    },
}